export default [
  {
    path: '/portfolios',
    name: 'portfolios',
    meta: {
      title: 'Portfolios',
      authRequired: true,
    },
    component: () => import('@/views/projects/Projects'),
  },
  {
    path: '/portfolios/category/:slug',
    name: 'portfolio-category',
    meta: {
      title: 'Portfolio Details',
      authRequired: true,
    },
    component: () => import('@/views/projects/ProjectCategory'),
  },
  {
    path: '/portfolios/:slug',
    name: 'portfolio-details',
    meta: {
      title: 'Portfolio Details',
      authRequired: true,
    },
    component: () => import('@/views/projects/ProjectDetails'),
  },
];
