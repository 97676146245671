export default {
  API_LOGIN: '/api/v1/members/login',
  API_LOGOUT: '/api/v1/members/logout',
  API_REGISTER: '/api/v1/members/register',
  API_PROFILE: '/api/v1/members/account',

  API_BANNERS: '/api/v1/members/cms/banners',
  API_ANNOUNCEMENTS: '/api/v1/members/cms/announcements',
  API_NEWS: '/api/v1/members/cms/news',
  API_PROJECT_CATEGORIES: '/api/v1/members/projects/project-categories',
  API_PROJECTS: '/api/v1/members/projects',
  API_PROJECT_DETAILS: '/api/v1/members/projects/:slug',
  API_PROJECT_VALIDATE_PASSWORD: '/api/v1/members/projects/password/:slug',
  API_PRODUCTS: '/api/v1/members/shop/products',
  API_PRODUCT_DETAILS: '/api/v1/members/shop/products/:slug',
  API_ADDRESSES: '/api/v1/members/account/addresses',
  API_VIP_CONTENTS: '/api/v1/members/cms/vip-contents',

  JSON_CONFIG: '/storage/data/config.json',
  JSON_BANNERS: '/storage/data/banners.json',
  JSON_ANNOUNCEMENTS: '/storage/data/announcements.json',
  JSON_NEWS: '/storage/data/news.json',
  JSON_NEWS_DETAILS: '/storage/data/news/:slug.json',
  JSON_PROJECT_CATEGORIES: '/storage/data/project-categories.json',
  JSON_PROJECTS: '/storage/data/projects.json',
  JSON_PRODUCTS: '/storage/data/products.json',
  JSON_VIP_CONTENTS: '/storage/data/vip-contents.json',
};
