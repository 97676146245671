<script>
export default {
  name: 'App',
  computed: {
    showLoginConfirm() {
      return this.$store.getters['modal/showLoginConfirm']
    },
  },
  methods: {
    goToLogin() {
      this.$router.push({ path: '/login' });
    },
    hidden() {
      this.$store.dispatch('modal/toggleLoginConfirmModal', {visible: false});
    },
  }
}
</script>
<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" :key="$route.name" />
  </router-view>
  
  <BModal 
    ref="loginConfirmationModal" 
    v-model="showLoginConfirm"
    class="v-modal-custom" 
    :title="$t('t-tip')" 
    :hide-header-close="true"
    :ok-title="$t('t-go-to-login')"
    @ok="goToLogin"
    @hidden="hidden"
    centered
  >
    {{ $t('t-please-login-first') }}
  </BModal>
</template>
