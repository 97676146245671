export default [
  {
    path: '/account',
    name: 'account',
    meta: {
      title: 'Account',
      authRequired: true,
    },
    component: () => import('@/views/account/Account'),
  },
  {
    path: '/account/edit',
    name: 'account-edit',
    meta: {
      title: 'Edit Account',
      authRequired: true,
    },
    component: () => import('@/views/account/AccountSetting'),
  },
  {
    path: '/account/fund-password',
    name: 'fund-password',
    meta: {
      title: 'Fund Password',
      authRequired: true,
    },
    component: () => import('@/views/account/FundPassword'),
  },
  {
    path: '/account/login-password',
    name: 'login-password',
    meta: {
      title: 'Login Password',
      authRequired: true,
    },
    component: () => import('@/views/account/LoginPassword'),
  },
  {
    path: '/account/bank-account',
    name: 'bank-accounts',
    meta: {
      title: 'Bank Account',
      authRequired: true,
    },
    component: () => import('@/views/account/BankAccount'),
  },
  {
    path: '/account/bank-account/add',
    name: 'edit-bank-accounts',
    meta: {
      title: 'Add Bank Account',
      authRequired: true,
    },
    component: () => import('@/views/account/BankAccountForm'),
  },
  {
    path: '/account/withdraw',
    name: 'withdraw',
    meta: {
      title: 'Withdraw',
      authRequired: true,
    },
    component: () => import('@/views/account/Withdraw'),
  },
  {
    path: '/account/investment-records',
    name: 'investment-records',
    meta: {
      title: 'Investment Records',
      authRequired: true,
    },
    component: () => import('@/views/history/JoinedRecord'),
  },
  {
    path: '/account/booking-records',
    name: 'booking-records',
    meta: {
      title: 'Booking Records',
      authRequired: true,
    },
    component: () => import('@/views/history/BookingRecord'),
  },
  {
    path: '/account/withdraw-records',
    name: 'withdraw-records',
    meta: {
      title: 'Withdraw Records',
      authRequired: true,
    },
    component: () => import('@/views/history/WithdrawRecord'),
  },
  {
    path: '/account/money-records',
    name: 'money-records',
    meta: {
      title: 'Money Records',
      authRequired: true,
    },
    component: () => import('@/views/history/MoneyRecord'),
  },
  // {
  //   path: '/account/about-us',
  //   name: 'about-us',
  //   meta: {
  //     title: 'About Us',
  //     authRequired: true,
  //   },
  //   component: () => import('@/views/miscellaneous/AboutUs'),
  // },
  // {
  //   path: '/account/faq',
  //   name: 'faq',
  //   meta: {
  //     title: 'FAQ',
  //     authRequired: true,
  //   },
  //   component: () => import('@/views/miscellaneous/Faq'),
  // },
];
